// Styles
import s from '../styles/pages/404.module.scss'
// Components
import { Button } from '../components/core/button/Button'
import { Container } from '../components/core/container/Container'
import { Icon } from '../components/core/icon/Icon'
import { Section } from '../components/core/section/Section'
import { Text } from '../components/core/text/Text'
import Meta from '../components/meta/Meta'

export default function Custom404() {
    return (
        <Section>
            <Meta
                title="Minningar - 404"
                seoTitle="Minningar - 404"
                description="404"
                type="website"
                url=""
                image="https://ik.imagekit.io/minningar/Minningar_7V4A5213_01-min_5-f7B_aBV.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1643189239548&tr=w-1200%2Ch-628%2Cfo-auto"
            />
            <Container>
                <div className={s.content}>
                    <Text variant="h1">404 | Síða fannst ekki</Text>
                    <Text variant="p-18">
                        Við biðjumst velvirðingar en engin síða finnst á þessari
                        slóð. Ástæðan gæti verið ein af eftirfarandi:
                    </Text>
                    {/* List of reasons */}
                    <div className={s.reason}>
                        <Icon variant="crumbArrow" />
                        <Text variant="p">
                            Slóðin á síðuna er ekki til, villa var gerð við
                            innslátt eða tengillinn sem vísaði á síðuna er
                            rangur
                        </Text>
                    </div>
                    <div className={s.reason}>
                        <Icon variant="crumbArrow" />
                        <Text variant="p">
                            Síðan hefur verið fjarlægð og er því ekki lengur til
                            á vefþjóni
                        </Text>
                    </div>
                    <div className={s.reason}>
                        <Icon variant="crumbArrow" />
                        <Text variant="p">
                            Síðan er ekki aðgengileg þessa stundina
                        </Text>
                    </div>
                    {/* Button */}
                    <Button
                        className={s.button}
                        type="button"
                        to="/"
                        variant="arrow"
                    >
                        Fara á forsíðu
                    </Button>
                </div>
            </Container>
        </Section>
    )
}
